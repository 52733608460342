<route>
{
  "meta": {
    "permission": [
      "customer.add_customer"
    ]
  }
}
</route>

<template>
  <i-container :opts="opts" :mode="container" @input="$emit('input', $event)">
    <v-card>
      <ValidationObserver v-slot="{ handleSubmit }" ref="form">
        <form @submit.prevent="handleSubmit(submit)">
          <i-toolbar
            :title="`${$tc('save', 1)} ${$tc('customer', 1)}`"
            :loading="loading"
          >
          </i-toolbar>
          <v-card-text>
            <diallevel v-model="dialog" :to-edit="toEdit" @done="addLevel" />
            <dialterms v-model="dialog2" :to-edit="toEdit2" @done="addTerms" />
            <v-row class="pt-4">
              <!-- Concurrencia del cliente -->
              <v-col cols="12" md="4">
                <h6>{{ $tc('concurrence') }}</h6>
                <div class="container-v-switch pt-2">
                  <label
                    :class="customer.permanent_customer ? '' : 'active'"
                    for="permanent_customer"
                  >
                    {{ $t('sporadic') }}
                  </label>
                  <v-switch
                    id="permanent_customer"
                    class="mt-0 pt-0"
                    color="primary"
                    name="permanent_customer"
                    v-model="customer.permanent_customer"
                  ></v-switch>
                  <label
                    :class="customer.permanent_customer ? 'active' : ''"
                    for="permanent_customer"
                  >
                    {{ $t('permanent') }}
                  </label>
                </div>
              </v-col>

              <!-- Activo -->
              <v-col cols="12" md="4">
                <h6>{{ $tc('status') }}</h6>
                <v-switch
                  class="v-switch mt-0 pt-0"
                  color="primary"
                  name="active"
                  :label="$t('active')"
                  v-model="customer.active"
                ></v-switch>
              </v-col>

              <!-- Notificaciones -->
              <v-col cols="12" md="4">
                <h6>{{ $tc('notifications') }}</h6>
                <v-switch
                  class="v-switch mt-0"
                  color="primary"
                  name="notification"
                  :label="$t('active')"
                  v-model="customer.notification"
                ></v-switch>
              </v-col>

              <!-- Nombre de la empresa -->
              <v-col cols="12" md="4">
                <ValidationProvider
                  vid="company_name"
                  :name="$t('company_name')"
                  :rules="customer.permanent_customer ? 'required' : ''"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    class="secondary--text"
                    outlined
                    persistent-hint
                    :error-messages="errors[0]"
                    :hint="
                      customer.permanent_customer ? $t('required_field') : ''
                    "
                    :label="$t('company_name')"
                    v-model="customer.company_name"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>

              <!-- Nombres -->
              <v-col cols="12" md="4">
                <ValidationProvider
                  vid="first_name"
                  rules="required"
                  :name="$t('first_name')"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    class="secondary--text required-icon"
                    outlined
                    persistent-hint
                    :error-messages="errors[0]"
                    :hint="$t('required_field')"
                    :label="$t('first_name')"
                    v-model="customer.first_name"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>

              <!-- Apellidos -->
              <v-col cols="12" md="4">
                <ValidationProvider
                  vid="last_name"
                  rules="required"
                  :name="$t('last_name')"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    class="secondary--text"
                    outlined
                    persistent-hint
                    :error-messages="errors[0]"
                    :hint="$t('required_field')"
                    :label="$t('last_name')"
                    v-model="customer.last_name"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>

              <!-- Correo -->
              <v-col cols="12" md="4">
                <ValidationProvider
                  vid="email"
                  :name="$tc('email', 1)"
                  :rules="
                    customer.permanent_customer ? 'required|email' : 'email'
                  "
                  v-slot="{ errors }"
                >
                  <v-text-field
                    class="secondary--text"
                    autocomplete="off"
                    key="email-input"
                    outlined
                    persistent-hint
                    :error-messages="errors[0]"
                    :hint="
                      customer.permanent_customer ? $t('required_field') : ''
                    "
                    :label="$tc('email', 1)"
                    v-model="customer.email"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>

              <!-- Teléfono -->
              <v-col cols="12" md="4">
                <ValidationProvider
                  vid="phone"
                  rules="required|numeric"
                  :name="$tc('phone', 1)"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    class="secondary--text"
                    counter
                    maxlength="14"
                    outlined
                    persistent-hint
                    :error-messages="errors[0]"
                    :hint="$t('required_field')"
                    :label="$t('phone_number')"
                    v-model="customer.phone"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>

              <!-- Tipo de cliente -->
              <v-col cols="12" md="4">
                <ValidationProvider
                  vid="level"
                  rules="required"
                  :name="$tc('level', 1)"
                  v-slot="{ errors }"
                >
                  <v-select
                    autocomplete="off"
                    item-text="name"
                    item-value="pk"
                    outlined
                    persistent-hint
                    :append-icon="levels.length < 4 ? 'fa-plus' : ''"
                    :error-messages="errors[0]"
                    :hint="$t('required_field')"
                    :items="levels"
                    :label="$tc('level', 1)"
                    v-model="customer.level_id"
                    @click:append="openLevel()"
                  ></v-select>
                </ValidationProvider>
              </v-col>

              <!-- Origen de cliente -->
              <v-col cols="12" md="4">
                <ValidationProvider
                  vid="origin"
                  rules="required"
                  :name="$t('customer_origin')"
                  v-slot="{ errors }"
                >
                  <v-select
                    autocomplete="off"
                    outlined
                    persistent-hint
                    item-text="name"
                    item-value="pk"
                    :error-messages="errors[0]"
                    :hint="$t('required_field')"
                    :items="origins"
                    :label="$t('customer_origin')"
                    v-model="customer.origin_id"
                  ></v-select>
                </ValidationProvider>
              </v-col>

              <!-- Fecha de origen de cliente -->
              <v-col cols="12" md="4">
                <ValidationProvider
                  vid="origin"
                  rules="required"
                  :name="$t('customer_origin_date')"
                  v-slot="{ errors }"
                >
                  <i-date-picker
                    v-model="customer.origin_date"
                    :label="$t('customer_origin_date')"
                    :error-messages="errors[0]"
                    color="primary"
                    :max="new Date().toISOString().substr(0, 10)"
                  />
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row
              v-if="getPermissions([`base_config.change_globaltax`]) || isAdmin"
              class="mt-3"
            >
              <!-- Impuesto -->
              <v-col cols="12" md="4">
                <v-switch
                  class="v-switch"
                  color="primary"
                  name="tax"
                  :label="$tc('tax', 1)"
                  v-model="customer.tax"
                ></v-switch>
              </v-col>

              <!-- Número de pago de impuestos -->
              <v-col cols="12" md="8" lg="4">
                <ValidationProvider
                  v-show="!customer.tax"
                  vid="tax_id"
                  :name="$tc('tax_id', 1)"
                  :rules="customer.tax ? '' : 'required|min:4|max:50'"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    class="secondary--text"
                    outlined
                    :error-messages="errors[0]"
                    :label="$tc('tax_id', 1)"
                    v-model="customer.tax_number"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row class="mt-0 pb-5">
              <!-- Crédito -->
              <v-col
                v-if="getPermissions([`base_config.view_term`]) || isAdmin"
                cols="12"
                md="4"
              >
                <v-switch
                  class="v-switch"
                  color="primary"
                  name="has_credit"
                  :label="$tc('has_credit', 1)"
                  v-model="customer.has_credit"
                ></v-switch>
              </v-col>

              <!-- Límite de crédito -->
              <v-col v-show="customer.has_credit" cols="12" md="4">
                <ValidationProvider
                  vid="credit_limit"
                  :name="$t('credit_limit')"
                  :rules="{ required: customer.has_credit }"
                  v-slot="{ errors }"
                >
                  <i-money
                    v-if="customer.has_credit"
                    class="secondary--text"
                    outlined
                    :disabled="
                      !getPermissions([`base_config.change_term`]) && !isAdmin
                    "
                    :error-messages="errors[0]"
                    :label="$t('credit_limit')"
                    v-model="customer.customercredit_set[0].credit_limit"
                  ></i-money>
                </ValidationProvider>
              </v-col>

              <!-- Días para pagar -->
              <v-col v-show="customer.has_credit" cols="12" md="4">
                <ValidationProvider
                  vid="day_terms"
                  :name="$t('day_terms')"
                  :rules="{ required: customer.has_credit }"
                  v-slot="{ errors }"
                >
                  <v-select
                    v-if="customer.has_credit"
                    class="secondary--text"
                    append-icon="fa-plus"
                    autocomplete="off"
                    item-text="days"
                    item-value="pk"
                    key="day_terms-input"
                    outlined
                    :disabled="
                      !getPermissions([`base_config.change_term`]) && !isAdmin
                    "
                    :error-messages="errors[0]"
                    :items="days"
                    :label="$t('day_terms')"
                    v-model="customer.customercredit_set[0].term"
                    @click:append="openTerms()"
                  ></v-select>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row class="infocard mx-0">
              <v-col cols="12">
                <h4 class="primary--text text--accent-3">
                  {{ $t('billing_address') }}
                </h4>
              </v-col>

              <!-- Dirección -->
              <v-col cols="12" md="4">
                <ValidationProvider
                  vid="address"
                  :name="$t('address')"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    outlined
                    class="secondary--text"
                    :error-messages="errors[0]"
                    :label="$t('address')"
                    v-model="customer.address_set[0].address1"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>

              <!-- Dirección 2 -->
              <v-col cols="12" md="4">
                <ValidationProvider
                  vid="address2"
                  :name="$t('address2')"
                  rules=""
                  v-slot="{ errors }"
                >
                  <v-text-field
                    autocomplete="off"
                    outlined
                    v-model="customer.address_set[0].address2"
                    :error-messages="errors[0]"
                    :label="$t('address2')"
                    class="secondary--text"
                    key="address2-input"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>

              <!-- Ciudad -->
              <v-col cols="12" md="4">
                <ValidationProvider
                  vid="city"
                  :name="$t('city')"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    autocomplete="off"
                    outlined
                    v-model="customer.address_set[0].city"
                    :error-messages="errors[0]"
                    :label="$t('city')"
                    class="secondary--text"
                    key="city-input"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>

              <!-- Estado -->
              <v-col cols="12" md="4">
                <ValidationProvider
                  vid="state"
                  :name="$t('state')"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    autocomplete="off"
                    outlined
                    v-model="customer.address_set[0].state"
                    :error-messages="errors[0]"
                    :label="$t('state')"
                    class="secondary--text"
                    key="state-input"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>

              <!-- Código postal -->
              <v-col cols="12" md="4">
                <ValidationProvider
                  vid="zip_code"
                  :name="$t('zip_code')"
                  rules="required|numeric"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    autocomplete="off"
                    outlined
                    v-model="customer.address_set[0].zip_code"
                    :error-messages="errors[0]"
                    :label="$t('zip_code')"
                    class="secondary--text"
                    key="zip_code-input"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row class="mt-3 pb-3">
              <!-- Igualar direcciones -->
              <v-col cols="12" md="4">
                <v-switch
                  class="v-switch"
                  color="primary"
                  name="billing"
                  :label="$t('same_billing')"
                  v-model="customer.sameBilling"
                ></v-switch>
              </v-col>
            </v-row>
            <v-row class="infocard mx-0 mb-2">
              <v-col cols="12">
                <h4 class="primary--text text--accent-3">
                  {{ $t('shipping_address') }}
                </h4>
              </v-col>

              <!-- Dirección -->
              <v-col cols="12" md="4">
                <ValidationProvider
                  vid="address3"
                  :name="$t('address')"
                  :rules="{ required: !customer.sameBilling }"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    class="secondary--text"
                    autocomplete="off"
                    key="address3-input"
                    outlined
                    :disabled="customer.sameBilling"
                    :error-messages="errors[0]"
                    :label="$t('address')"
                    v-model="customer.address_set[1].address1"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>

              <!-- Dirección 2 -->
              <v-col cols="12" md="4">
                <ValidationProvider
                  vid="address4"
                  :name="$t('address')"
                  rules=""
                  v-slot="{ errors }"
                >
                  <v-text-field
                    class="secondary--text"
                    autocomplete="off"
                    key="address4-input"
                    outlined
                    :disabled="customer.sameBilling"
                    :error-messages="errors[0]"
                    :label="$t('address2')"
                    v-model="customer.address_set[1].address2"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>

              <!-- Ciudad -->
              <v-col cols="12" md="4">
                <ValidationProvider
                  vid="city2"
                  :name="$t('city')"
                  :rules="{ required: !customer.sameBilling }"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    class="secondary--text"
                    autocomplete="off"
                    key="city-input"
                    outlined
                    :disabled="customer.sameBilling"
                    :error-messages="errors[0]"
                    :label="$t('city')"
                    v-model="customer.address_set[1].city"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>

              <!-- Estado -->
              <v-col cols="12" md="4">
                <ValidationProvider
                  vid="state2"
                  :name="$t('state')"
                  :rules="{ required: !customer.sameBilling }"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    class="secondary--text"
                    autocomplete="off"
                    key="state-input"
                    outlined
                    :disabled="customer.sameBilling"
                    :error-messages="errors[0]"
                    :label="$t('state')"
                    v-model="customer.address_set[1].state"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>

              <!-- Código postal -->
              <v-col cols="12" md="4">
                <ValidationProvider
                  vid="zip_code2"
                  :name="$t('zip_code')"
                  :rules="{
                    required: !customer.sameBilling,
                    numeric: 'numeric'
                  }"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    class="secondary--text"
                    key="zip_code-input"
                    outlined
                    :disabled="customer.sameBilling"
                    :error-messages="errors[0]"
                    :label="$t('zip_code')"
                    v-model="customer.address_set[1].zip_code"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row class="mt-3 pb-3">
              <!-- Calificación -->
              <v-col cols="12">
                <h6>{{ $tc('rating') }}</h6>
                <v-rating
                  background-color="primary"
                  color="warning"
                  clearable
                  empty-icon="far fa-star"
                  full-icon="fas fa-star"
                  hover
                  length="5"
                  size="30"
                  v-model="customer.stars"
                ></v-rating>
              </v-col>
            </v-row>
            <v-row class="mt-3">
              <!-- Reseña -->
              <v-col cols="12">
                <v-textarea
                  class="secondary--text"
                  name="address"
                  rows="3"
                  outlined
                  :label="$t('review')"
                  v-model="customer.review"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
        </form>
      </ValidationObserver>
    </v-card>
  </i-container>
</template>
<script>
import { mapGetters } from 'vuex'
import dialterms from '../../components/terms/modal.vue'
import diallevel from '../../components/level/modal.vue'

export default {
  props: {
    container: {
      type: String,
      default: 'container'
    },
    value: {
      type: Boolean,
      default: false
    }
  },
  components: {
    dialterms,
    diallevel
  },
  data() {
    return {
      dialog: false,
      dialog2: false,
      toEdit: null,
      toEdit2: null,
      tab: 'tab-1',
      sameBilling: false,
      load: false,
      loading: false,
      levels: [],
      countries: [],
      days: [],
      origins: [],
      mode: 1,
      customerPartial: '',
      customer: {
        pk: '',
        company_name: '',
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        tax: true,
        tax_id: '',
        hasUser: false,
        has_credit: false,
        level_id: null,
        active: true,
        user: null,
        stars: null,
        review: '',
        permanent_customer: false,
        company_id: 1,
        special: false,
        notification: false,
        origin_id: '',
        origin_date: new Date().toISOString().substr(0, 10),
        address_set: [
          {
            address1: '',
            address2: '',
            city: '',
            state: '',
            zip_code: null,
            country: null,
            address_type: 'BILL'
          },
          {
            address1: '',
            address2: '',
            city: '',
            state: '',
            zip_code: null,
            country: null,
            address_type: 'SHIP'
          }
        ],
        customercredit_set: [{ credit_limit: 0, term: 0 }]
      },
      empty: false
    }
  },
  computed: {
    opts() {
      switch (this.container) {
        case 'modal':
          return {
            title: this.$t(this.edit ? 'editing' : 'add', {
              value: this.$tc('customer', 1)
            }),
            value: this.value,
            load: this.loading,
            maxWidth: '80%',
            dontSave: this.mode == 1
          }
        default:
          return { fluid: true }
      }
    },
    ...mapGetters({
      getPermissions: 'session/getPermissions',
      isAdmin: 'session/isAdmin',
      myCompanies: 'session/myCompanies'
    })
  },
  methods: {
    openLevel(item) {
      this.toEdit = item === undefined ? null : item
      this.dialog = true
    },
    openTerms(item) {
      this.toEdit = item === undefined ? null : item
      this.dialog2 = true
    },
    async submit(empty) {
      try {
        this.load = true
        if (this.customer.sameBilling) {
          this.customer.address_set[1] = {
            ...this.customer.address_set[0],
            address_type: 'SHIP'
          }
        }
        if (!this.customer.has_credit) {
          delete this.customer.customercredit_set
        }
        this.customerPartial = await this.$api.customer.create({
          form: this.customer
        })
        this.$emit('input', false)
        this.$emit('updated', this.customer)
        this.$toast.success(
          `${this.$tc('customer', 1)} ${this.$tc('created', 2)}`
        )
        this.customerPartial = this.customerPartial.data
      } catch (error) {
        console.error(error)
      } finally {
        this.load = false
        // Redirecciona siempre que no esté en modo modal
        if (this.container !== 'modal') {
          this.$router.push({ name: 'customer' })
        }
      }
    },
    async getCountries() {
      const country = await this.$api.country.list({})
      this.countries = country.data
    },
    async getLevels() {
      const level = await this.$api.customer.level.list({})
      this.levels = level.data.results
    },
    async getTerms() {
      const terms = await this.$api.terms.list({})
      this.days = terms.data.results
    },
    async getCustomerOrigin() {
      const origin = await this.$api.customerOrigin.list({})
      this.origins = origin.data.results
    },
    addLevel(evt) {
      this.getLevels()
      this.customer.level_id = evt.pk
    },
    addTerms(evt) {
      this.getTerms()
      this.customer.customercredit_set[0].term = evt.pk
    }
  },
  mounted() {
    this.getCountries()
    this.getLevels()
    this.getTerms()
    this.getCustomerOrigin()
    this.customer.company_id = this.myCompanies[0].id
  },
  watch: {
    'customer.sameBilling': {
      handler(val) {
        if (val) {
          this.customer.address_set[1] = {
            ...this.customer.address_set[0],
            address_type: 'SHIP'
          }
        }
      }
    },
    'customer.hasUser': {
      handler(val) {
        if (val) {
          this.customer.user.first_name = this.customer.first_name
          this.customer.user.last_name = this.customer.last_name
          this.customer.user.email = this.customer.email
        } else {
          this.customer.user.first_name = ''
          this.customer.user.last_name = ''
          this.customer.user.email = ''
        }
      }
    }
  }
}
</script>
